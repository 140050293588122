import React from "react";
import PropTypes, { string } from "prop-types";

function Portfolio({ portfolio }) {
  if (portfolio.displayType === "main")
    return (
      <div className="box-item">
        <div className="image">
          <a
            aria-label="main image"
            href={`#popup-${portfolio.id}`}
            className="has-popup-media"
          >
            <img src={`${portfolio.gallery[0]}`} alt="" />
            <span className="info">
              <span className="ion ion-images" />
            </span>
          </a>
        </div>
        <div className="desc">
          <a href={`#popup-${portfolio.id}`} className="name has-popup-media">
            {" "}
            {portfolio.projectName}{" "}
          </a>
          <div className="category">{portfolio.type}</div>
        </div>
        <div
          id={`popup-${portfolio.id}`}
          className="popup-box mfp-fade mfp-hide"
        >
          <div className="content">
            <div className="image">
              <img src={`${portfolio.gallery[0]}`} alt="" />
            </div>
            <div className="desc">
              <div className="post-box">
                <h1>{portfolio.projectName}</h1>
                {/* <div className="blog-detail">{portfolio.type}</div> */}
                <div className="blog-content">
                  <p>
                    <strong> Description </strong>
                  </p>
                  <p> {portfolio.description} </p>

                  <p>
                    <strong> Key Features</strong>
                  </p>
                  {portfolio.keyFeatures.map((feature) => (
                    <blockquote>{feature}</blockquote>
                  ))}
                  <p>
                    <strong> Technologies</strong>
                  </p>
                  <ul className="list-style">
                    {portfolio.skills.map((skill) => (
                      <li>{skill}</li>
                    ))}
                  </ul>
                </div>
                <a href={portfolio.liveLink} className="button">
                  <span className="text">View Project</span>
                  <span className="arrow" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  return (
    <div className="box-item">
      <div className="image">
        <a
          aria-label="main image"
          href={`${portfolio.liveLink}`}
          className="has-popup-video"
        >
          <img src={`${portfolio.gallery[0]}`} alt="" />
          <span className="info">
            <span className="ion ion-videocamera" />
          </span>
        </a>
      </div>
      <div className="desc">
        <a href={`${portfolio.liveLink}`} className="name name has-popup-video">
          {" "}
          {portfolio.projectName}{" "}
        </a>
        <div className="category">{portfolio.type}</div>
      </div>
    </div>
  );
}

Portfolio.propTypes = {
  portfolio: PropTypes.shape({
    id: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    gallery: PropTypes.arrayOf([string]).isRequired,
    keyFeatures: PropTypes.arrayOf([string]).isRequired,
    projectName: PropTypes.string.isRequired,
    skills: PropTypes.arrayOf([string]).isRequired,
    type: PropTypes.string.isRequired,
    liveLink: PropTypes.string.isRequired,
    displayType: PropTypes.string.isRequired,
  }).isRequired,
};

export default Portfolio;
