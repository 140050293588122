/* eslint-disable prettier/prettier */
import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import "./index.css";
// imports components
import RootEl from "./routes/root";
// import Index from './routes/index';
// import Blogs from './routes/blogs';
// import Blog from './routes/blog'
// import Resume from './routes/resume';
// import Works from './routes/works';
// import Contact from './routes/contact';

import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootEl />,
    // children: [
    //   {index: true, element: <Index/>},
    //   {path: 'about', element: <Index/>},
    //   {path: 'resume', element: <Resume/>},
    //   {path: 'works', element: <Works/>},
    //   {path: 'blogs', element: <Blogs/>},
    //   {path: 'blogs/:blogId', element: <Blog/>},
    //   {path: 'contact', element: <Contact/>},
    // ]
  },
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
    {/* <App /> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
